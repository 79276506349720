import React, { useState } from "react";
import "./login.css";
import logo from "../assets/logo.svg";
import { FaLock } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";

const Login = () => {
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [phNumber, setphNumner] = useState(null);
  const [otp, setOtp] = useState(null);

  function handleSubmit(e) {
    e.preventDefault(); // Prevent the form from submitting
    setShowOtpBox(true);
    setShowLoginForm(false);
  }

  return (
    <div className="m-0 h-screen w-screen overflow-hidden font-sans font-bold flex items-center justify-center text-gray-600 bg-gray-200">
      <div className="login-div">
        <div class="logo">
          <img src={logo} alt="Cooliewale Logo" />
        </div>
        <div className="text-center text-3xl pt-6 mb-4">Passenger Login</div>
        {showLoginForm && (
          <form>
            <div>
              <p>Enter your Mobile Number</p>
              <p>A verification code will be sent to your phone.</p>
              <div className="w-full pt-12 pb-1 pl-1 pr-1 fields">
                <div className="shadow-custom-inset mb-10 rounded-[25px] flex flex-row items-center">
                  <FaMobileAlt />
                  <input
                    type="tel"
                    className="mob-input"
                    placeholder=""
                    maxLength="10"
                    onChange={(e) => {
                      setphNumner(e.target.vale);
                    }}
                    value={phNumber}
                    required
                  />
                </div>
              </div>
            </div>
            <button className="signin-button" onClick={handleSubmit}>
              Login with number
            </button>
          </form>
        )}

        {showOtpBox && (
          <div>
            <h1>Enter OTP</h1>
            <p>
              Please enter the 6-digit code sent to <span></span>
            </p>
            <form>
              <div>
                <div className="flex flex-row mt-2 mb-2">
                  <FaLock />
                  <div className="username">
                    <input
                      type="text"
                      className="otp-input separate-digits"
                      placeholder=""
                      maxLength="6"
                      onChange={(e) => {
                        setOtp(e.target.vale);
                      }}
                      value={otp}
                      required
                    />
                  </div>
                </div>
              </div>
              <button className="signin-button">OTP</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
