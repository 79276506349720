import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Pages/login";
import OtpForm  from "./components/otp_form";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Home route */}
        <Route path="/" element={<Login />} />


        {/* OTP form route */}
        <Route path="/otp_form" element={<OtpForm />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
